import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GA4React from 'ga-4-react'
import trackingEvents from './constants/trackingEvents'

let ga4Instance
let trackingCodesArray = []

export const init = async (trackingCodes = [], customDimensions = []) => {
  try {
    const parsedCustomDimensionValues = customDimensions.reduce((acc, val) => {
      acc[val.key] = val.value
      return acc
    }, {})

    trackingCodesArray = trackingCodes

    await initialiseGA4(trackingCodes[0])

    if (ga4Instance && typeof ga4Instance.gtag === 'function') {
      trackingCodes.forEach(code => {
        ga4Instance.gtag('config', code, {
          send_page_view: false,
          app_version: parsedCustomDimensionValues.app_version
        })
      })
      ga4Instance.gtag('event', 'bidJS_load', parsedCustomDimensionValues)
    }
  } catch (e) {
    console.warn(e)
  }
}

export const setUser = (userUuid = null, language = null) => {
  try {
    if (ga4Instance && typeof ga4Instance.gtag === 'function') {
      trackingCodesArray.forEach(code => {
        ga4Instance.gtag('config', code, {
          user_id: userUuid,
          language
        })
      })

      ga4Instance.gtag('event', userUuid ? trackingEvents.ACTION_LOGIN : trackingEvents.ACTION_LOGOUT, {
        user_id: userUuid
      })
    }
  } catch (e) {
    console.warn(e)
  }
}

export const trackPage = (page) => {
  try {
    if (ga4Instance && typeof ga4Instance.gtag === 'function') {
      ga4Instance.gtag('event', 'page_view', {
        page_path: page,
        page_location: window?.location.href
      })
    }
  } catch (e) {
    console.warn(e)
  }
  if (window.bidjs?.callback) {
    window.bidjs.callback({
      action: 'BIDJS_MODULES_NAVIGATED',
      data: {
        path: page
      }
    })
  }
}

export const trackEvent = (category, action, label, value) => {
  const event = {
    action,
    event_category: category,
    event_label: label,
    page_location: window.location.href,
    value
  }
  try {
    if (ga4Instance && typeof ga4Instance.gtag === 'function') {
      ga4Instance.gtag('event', action, event)
    }
  } catch (e) {
    console.warn(e)
  }
  if (window.bidjs?.callback) {
    window.bidjs.callback({
      action: 'BIDJS_TRACKING_EVENT',
      data: event
    })
  }
}

export const trackModal = (name) => {
  try {
    if (ga4Instance && typeof ga4Instance.gtag === 'function') {
      ga4Instance.gtag('event', 'page_view', {
        page_path: `/modal/${name}`
      })
    }
  } catch (e) {
    console.warn(e)
  }
  if (window.bidjs?.callback) {
    window.bidjs.callback({
      action: 'BIDJS_MODAL_VIEW',
      data: name
    })
  }
}

const initialiseGA4 = async (code) => {
  try {
    ga4Instance = new GA4React(code)
    await ga4Instance.initialize()
  } catch (e) {
    console.warn(e)
  }
}

export default function withTracking (WrappedComponent) {
  const withTracker = class extends Component {
    componentDidMount () {
      const page = this.props.location.pathname
      trackPage(page)
    }

    componentDidUpdate (prevProps) {
      const currentPage = this.props.location.pathname
      const prevPage = prevProps.location.pathname

      if (currentPage !== prevPage) {
        trackPage(currentPage)
      }
    }

    render () {
      return <WrappedComponent {...this.props} trackEvent={trackEvent} trackModal={trackModal} />
    }
  }

  withTracker.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  }

  return withTracker
}
